import { useCallback, useEffect, useRef } from 'react';
import type { RefObject } from 'react';
import type React from 'react';

type OnClickListener = EventListenerOrEventListenerObject;

interface ClickOutsideProps {
  active?: boolean;
  render: (props: {
    innerRef: RefObject<HTMLDivElement> | ((element: HTMLDivElement) => void);
    onPointerDownCapture: () => void;
  }) => React.ReactElement;
  root?: ShadowRoot | HTMLElement;
  onClick?: OnClickListener;
  additionalConditionals?: (e: HTMLElement) => boolean;
}

function ClickOutside({
  active = true,
  render,
  onClick,
  additionalConditionals,
  root,
}: ClickOutsideProps): React.ReactElement {
  const ref = useRef<HTMLDivElement>();
  const clickProp = useRef<OnClickListener>();
  const captureFlag = useRef(false);

  useEffect(() => {
    clickProp.current = onClick;
  });

  useEffect(() => {
    const handleClick: OnClickListener = (event) => {
      if (additionalConditionals?.(event.target as HTMLElement)) {
        return;
      }

      /**
       * This was added to prevent the modal closing when there was a credit card input field inside the modal. The modal would close if the user had a password manager (like 1Password). When the user clicked on the 1Password button, the modal would close.
       *
       * The cause is that extensions mount a "portal" for their UI. This portal is a sibling to the portal we use to render the modal. Since the extension's portal is outside of the modal's, interacting with any extension UI will cause the modal to close.
       *
       * The assumption of this fix is that the extensions UI will always render above and within bounds of the modal. When we receive a pointer event, we check to see if it's within the bounds of the modal. If it isn't, we close the modal.
       *
       * This changes the behavior
       * from: a click outside is determined by the DOM structure
       * to: a click outside is determined if it's within the visual bounds of the root element
       */
      if (ref.current && isMouseEventInsideElement(event, ref.current)) {
        return;
      }

      // React bubbled the event through the REACT TREE, not the DOM tree
      // we should ignore the click event, because it's guaranteed that
      // the click happened within the wrapped element
      //
      // NB: This code will break on Preact as it doesn't propagate events through the React tree
      // (if we ever switched to Preact for some reason)
      if (captureFlag.current) {
        captureFlag.current = false;
        return;
      }

      if (!ref.current?.contains(event.target as Node)) {
        if (typeof clickProp.current === 'function') {
          clickProp.current(event);
        }
      }
    };

    const rootNode = root ?? document;

    if (active) {
      rootNode.addEventListener('pointerdown', handleClick);
    }

    return (): void => {
      rootNode.removeEventListener('pointerdown', handleClick);
    };
  }, [active, root, additionalConditionals]);

  const handleRef = useCallback((node: HTMLDivElement) => {
    ref.current = node;
  }, []);

  return render({
    innerRef: handleRef,
    // This solves listening for events across React Portals,
    // where the clicked element may not share a parent in the DOM tree
    // but should not be considered a click "outside"
    //
    // Listen for pointer down during CAPTURE phase, so this runs before
    // document will receive any mousedown/touchstart events
    onPointerDownCapture: () => {
      captureFlag.current = true;
    },
  });
}

/* eslint-disable-next-line import/no-default-export -- TODO: Fix ESLint Error (#13355) */
export default ClickOutside;

function isMouseEventInsideElement(
  event: Event | MouseEvent,
  element: Element,
): boolean {
  if (!('clientY' in event)) return false;
  const rect = element.getBoundingClientRect();
  if (rect.width === 0 || rect.height === 0) return false;
  return (
    rect.top <= event.clientY &&
    event.clientY <= rect.top + rect.height &&
    rect.left <= event.clientX &&
    event.clientX <= rect.left + rect.width
  );
}
