'use client';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  type JSX,
} from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { clsx } from 'clsx';
import { CheckInCircle as IconCheckInCircle } from '../../icons/check-in-circle';
import { FaceHappy } from '../../new-icons/16/face-happy';
import { FaceSad } from '../../new-icons/16/face-sad';
import { FaceSmile } from '../../new-icons/16/face-smile';
import { FaceUnhappy } from '../../new-icons/16/face-unhappy';
import { Text } from '../text';
import type { ButtonProps } from '../button';
import { Button } from '../button';
import { Input } from '../input';
import styles from './feedback.module.css';
import ClickOutside from './click-outside';

const variants = {
  open: { height: 'auto', borderRadius: 12 },
  openFixed: { height: 243, width: 336, borderRadius: 12 },
  openFixedError: { height: 271, width: 336, borderRadius: 12 },
  openFixedUpwards: { height: 245, width: 336, borderRadius: 12, y: -200 },
  openFixedUpwardsError: { height: 245, width: 336, borderRadius: 12, y: -100 },
  closed: { height: 48, borderRadius: 30 },
};

const EMOJIS = new Map([
  ['🤩', 'f929'],
  ['🙂', 'f600'],
  ['😕', 'f615'],
  ['😭', 'f62d'],
]);

function getEmoji(code: string | null): string | null {
  if (!code) return null;

  const EMOJI_CODES = new Map([...EMOJIS].map(([k, v]) => [v, k]));

  return EMOJI_CODES.get(code) || '';
}

function LoveItIcon(): JSX.Element {
  return <FaceSmile />;
}

function ItsOkayIcon(): JSX.Element {
  return <FaceHappy />;
}

function UpsetIcon(): JSX.Element {
  return <FaceUnhappy />;
}

function CryingIcon(): JSX.Element {
  return <FaceSad />;
}

const EMOJI_ICONS = [
  <LoveItIcon key="love-it" />,
  <ItsOkayIcon key="its-okay" />,
  <UpsetIcon key="upset-icon" />,
  <CryingIcon key="crying-icon" />,
];

const EMOJI_DESCRIPTIONS = ['Love it!', 'It’s okay', 'Not great', 'Hate it'];

export const SHOW_FEEDBACK_EVENT = 'show-feedback';

type SiteProperties = 'next-site' | 'turbo-site' | 'front';

export function Feedback({
  dryRun,
  label,
  planName,
  showEmail,
  email: emailValue,
  type,
  siteType = 'front',
  upwards,
  copy,
  buttonType = 'secondary',
}: {
  dryRun?: boolean;
  copy?: string;
  label?: string;
  planName?: string;
  email?: string;
  type?: 'default' | 'inline';
  plan?: string;
  upwards?: boolean;
  siteType?: SiteProperties;
  showEmail?: boolean;
  buttonType?: ButtonProps['type'];
}): JSX.Element {
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [readyToBeRemoved, setReadyToBeRemoved] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [email, setEmail] = useState('');
  const [value, setValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const getProperty = (
    site: SiteProperties,
  ): { requestUrl: string; baseUrl: string } => {
    if (site === 'front') {
      return {
        requestUrl: '/api/feedback',
        baseUrl: 'https://vercel.com',
      };
    }

    if (site === 'turbo-site') {
      return {
        requestUrl: '/api/feedback',
        baseUrl: 'https://turbo.build',
      };
    }

    // next-site
    return {
      requestUrl: 'https://api.nextjs.org/api/feedback',
      baseUrl: 'https://nextjs.org',
    };
  };

  const onSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>): void => {
      e?.preventDefault();

      if (dryRun) {
        setSuccess(true);
        setLoading(false);
        setValue('');
      }

      if (!value) {
        setErrorMessage('Please enter your feedback');
        return;
      }

      if (!selectedEmoji) {
        setErrorMessage('Please select an emoji');
        return;
      }

      if (showEmail && !email) {
        setErrorMessage('Please enter your email');
        return;
      }

      setLoading(true);

      const emotion = getEmoji(selectedEmoji);

      if (!emotion) return;

      const site = getProperty(siteType);

      fetch(site.requestUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          url:
            window.location.hostname === 'localhost'
              ? `${site.baseUrl}/dev-mode${window.location.pathname}`
              : window.location.toString(),
          note: value,
          email: emailValue || email,
          emotion,
          plan: planName,
          label,
          ua: `${siteType} ${
            process.env.NEXT_PUBLIC_VERCEL_ENV || ''
          } + ${navigator.userAgent} (${
            navigator.language || 'unknown language'
          })`,
        }),
      })
        .then(() => {
          // Reset the textarea value on success
          setSuccess(true);
          setValue('');
          setEmail('');
          // TODO: Add analytics
          // analytics.track(AnalyticsEvent.FEEDBACK_SUBMITTED, {
          //   emoji: emotion,
          //   note: textAreaRef.current?.value || '',
          // });
        })
        .catch((err) => {
          if (err instanceof Error) {
            setErrorMessage(err.message);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [
      siteType,
      dryRun,
      email,
      emailValue,
      label,
      planName,
      selectedEmoji,
      value,
      showEmail,
    ],
  );

  useEffect(() => {
    if (!isOpen) {
      setSelectedEmoji(null);
    }
  }, [isOpen]);

  // Allows triggering the input using a custom event.
  useEffect(() => {
    const handleShowEvent = (): void => {
      setIsOpen(true);
      textareaRef;
    };
    document.addEventListener(SHOW_FEEDBACK_EVENT, handleShowEvent);
    return () =>
      document.removeEventListener(SHOW_FEEDBACK_EVENT, handleShowEvent);
  }, [setIsOpen]);

  useEffect(() => {
    let closeAfterSuccessTimer: NodeJS.Timeout | null = null;

    if (readyToBeRemoved && !hovering) {
      setIsOpen(false);
    }

    if (success) {
      // collapse in 4s
      closeAfterSuccessTimer = setTimeout(() => {
        if (!document.hidden && !hovering) {
          setIsOpen(false);

          setTimeout(() => {
            setValue('');
            setEmail('');
            setSelectedEmoji(null);
            setSuccess(false);
          }, 150);
        } else {
          setReadyToBeRemoved(true);
        }
      }, 4000);
    }

    return () => {
      if (closeAfterSuccessTimer !== null) {
        clearTimeout(closeAfterSuccessTimer);
        closeAfterSuccessTimer = null;
      }
    };
  }, [success, setIsOpen, isOpen, hovering, readyToBeRemoved]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.metaKey) {
        onSubmit();
      }

      if (type === 'inline' && e.key === 'Escape') {
        setIsOpen(false);
      }
    },
    [onSubmit, type],
  );

  useEffect(() => {
    function onKeydown(e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    }
    document.addEventListener('keydown', onKeydown);

    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, []);

  const fixedType = useMemo(() => {
    if (upwards) {
      return errorMessage ? 'openFixedUpwardsError' : 'openFixedUpwards';
    }

    return errorMessage ? 'openFixedError' : 'openFixed';
  }, [errorMessage, upwards]);

  if (type === 'inline') {
    return (
      <ClickOutside
        active={isOpen}
        additionalConditionals={(e): boolean => {
          let parent: HTMLElement | null = e;

          while (parent) {
            // Run this only once when clicked on the overlay
            if (parent.hasAttribute('data-feedback-inline')) return true;

            parent = parent.parentElement;
          }

          return false;
        }}
        onClick={() => setIsOpen(false)}
        render={() => (
          <div
            className={clsx(styles.inlineWrapper, {
              [String(styles.inlineWrapperUpwards)]: upwards,
              [String(styles.inlineWrapperClosed)]: !isOpen,
            })}
            data-feedback-inline=""
          >
            <motion.div
              animate={isOpen ? fixedType : 'closed'}
              className={styles.inlineTriggerWrapper}
              initial="closed"
              transition={{ duration: 0.15, ease: 'easeOut' }}
              variants={variants}
            >
              <div className={styles.trigger}>
                <Text color="gray-900" size={14}>
                  {copy ? copy : 'Was this helpful?'}
                </Text>
                <span className={styles.emojisWrapper}>
                  {Array.from(EMOJIS.values()).map((emoji, index) => (
                    <button
                      aria-checked={selectedEmoji === emoji}
                      aria-label={`Select ${EMOJI_DESCRIPTIONS[index] || ''} emoji`}
                      className={styles.emoji}
                      key={emoji}
                      onClick={(): void => {
                        setIsOpen(true);
                        if (!isOpen) {
                          setTimeout(() => {
                            textareaRef.current?.focus();
                          }, 150);
                        }
                        if (selectedEmoji === emoji) {
                          setSelectedEmoji(null);

                          if (isOpen) {
                            setIsOpen(false);
                          }
                          return;
                        }

                        setSelectedEmoji(emoji);
                      }}
                      role="radio"
                      type="button"
                    >
                      {EMOJI_ICONS[index]}
                    </button>
                  ))}
                </span>
              </div>
              <AnimatePresence>
                {!success ? (
                  <motion.div
                    exit={{ opacity: 0, y: -4 }}
                    key="form"
                    onKeyDown={(e): void =>
                      onKeyDown(e as unknown as KeyboardEvent)
                    }
                    transition={{ duration: 0.2 }}
                  >
                    <form onSubmit={onSubmit}>
                      <div className={styles.formWrapper}>
                        {showEmail ? (
                          <Input
                            aria-labelledby="E-mail"
                            onChange={(e): void => setEmail(e.target.value)}
                            placeholder="Email Address"
                            ref={inputRef}
                            typeName="email"
                            value={email}
                          />
                        ) : null}
                        <textarea
                          className={styles.textarea}
                          id="feedback-textarea"
                          onChange={(e): void => setValue(e.target.value)}
                          onFocus={() => setIsOpen(true)}
                          placeholder="Your feedback..."
                          ref={textareaRef}
                          value={value}
                        />
                        <AnimatePresence>
                          {errorMessage ? (
                            <motion.div
                              animate={{ height: 'auto', opacity: 1 }}
                              exit={{ height: 0, opacity: 0 }}
                              initial={{ height: 0, opacity: 0 }}
                              transition={{ duration: 0.2 }}
                            >
                              <Text
                                color="red-900"
                                style={{ display: 'block' }}
                              >
                                {errorMessage}
                              </Text>
                            </motion.div>
                          ) : null}
                        </AnimatePresence>
                        <Text
                          as="div"
                          className={styles['markdown-tip']}
                          color="gray-900"
                          size={12}
                        >
                          <svg
                            fill="none"
                            height="14"
                            viewBox="0 0 22 14"
                            width="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              clipRule="evenodd"
                              d="M19.5 1.25H2.5C1.80964 1.25 1.25 1.80964 1.25 2.5V11.5C1.25 12.1904 1.80964 12.75 2.5 12.75H19.5C20.1904 12.75 20.75 12.1904 20.75 11.5V2.5C20.75 1.80964 20.1904 1.25 19.5 1.25ZM2.5 0C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H19.5C20.8807 14 22 12.8807 22 11.5V2.5C22 1.11929 20.8807 0 19.5 0H2.5ZM3 3.5H4H4.25H4.6899L4.98715 3.82428L7 6.02011L9.01285 3.82428L9.3101 3.5H9.75H10H11V4.5V10.5H9V6.79807L7.73715 8.17572L7 8.97989L6.26285 8.17572L5 6.79807V10.5H3V4.5V3.5ZM15 7V3.5H17V7H19.5L17 9.5L16 10.5L15 9.5L12.5 7H15Z"
                              fill="var(--ds-gray-700)"
                              fillRule="evenodd"
                            />
                          </svg>
                          supported.
                        </Text>
                      </div>
                      <div
                        className={styles.actions}
                        style={{ justifyContent: 'flex-end' }}
                      >
                        <Button
                          loading={loading}
                          size="small"
                          typeName="submit"
                        >
                          Send
                        </Button>
                      </div>
                    </form>
                  </motion.div>
                ) : (
                  <div
                    className={styles.successWrapper}
                    key="success"
                    style={{ height: 'auto', paddingTop: 48 }}
                  >
                    <IconCheckInCircle
                      className="checkmark"
                      color="var(--geist-success)"
                      fill
                      size="2rem"
                    />
                    <Text size={14}>Your feedback has been received!</Text>
                    <Text size={14}>Thank you for your help.</Text>
                  </div>
                )}
              </AnimatePresence>
            </motion.div>
          </div>
        )}
      />
    );
  }

  return (
    <PopoverPrimitive.Root
      onOpenChange={(o): void => {
        if (o) {
          setSuccess(false);
          setErrorMessage(null);
          setReadyToBeRemoved(false);
          setSelectedEmoji(null);
          textareaRef.current?.focus();
          inputRef.current?.focus();
        }
        setIsOpen(o);
      }}
      open={isOpen}
    >
      <PopoverPrimitive.Trigger asChild>
        <Button
          className="hover:!text-gray-1000 !font-normal !text-gray-900"
          size="small"
          style={{
            width: 'var(--navbar-secondary-button-width)',
          }}
          type={buttonType}
        >
          Feedback
        </Button>
      </PopoverPrimitive.Trigger>
      <PopoverPrimitive.Content
        className={clsx(styles.popover, {
          [styles.success || '']: success,
        })}
        onKeyDown={(e): void => onKeyDown(e as unknown as KeyboardEvent)}
        onMouseEnter={(): void => setHovering(true)}
        onMouseLeave={(): void => setHovering(false)}
        onMouseMove={(): void => setHovering(true)}
        sideOffset={8}
      >
        <AnimatePresence>
          {!success ? (
            <motion.div
              exit={{ opacity: 0, y: -4 }}
              key="form"
              transition={{ duration: 0.2 }}
            >
              <form onSubmit={onSubmit}>
                <div className={styles.formWrapper}>
                  {showEmail ? (
                    <Input
                      aria-labelledby="E-mail"
                      onChange={(e): void => setEmail(e.target.value)}
                      placeholder="Email Address"
                      ref={inputRef}
                      typeName="email"
                      value={email}
                    />
                  ) : null}
                  <textarea
                    className={styles.textarea}
                    id="feedback-textarea"
                    onChange={(e): void => setValue(e.target.value)}
                    onFocus={() => setIsOpen(true)}
                    placeholder="Your feedback..."
                    ref={textareaRef}
                    value={value}
                  />
                  <AnimatePresence>
                    {errorMessage ? (
                      <motion.div
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        initial={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        <Text
                          color="red-900"
                          style={{ paddingTop: 4, display: 'block' }}
                        >
                          {errorMessage}
                        </Text>
                      </motion.div>
                    ) : null}
                  </AnimatePresence>
                  <Text
                    as="div"
                    className={styles['markdown-tip']}
                    color="gray-900"
                    size={12}
                  >
                    <svg
                      fill="none"
                      height="14"
                      viewBox="0 0 22 14"
                      width="22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        clipRule="evenodd"
                        d="M19.5 1.25H2.5C1.80964 1.25 1.25 1.80964 1.25 2.5V11.5C1.25 12.1904 1.80964 12.75 2.5 12.75H19.5C20.1904 12.75 20.75 12.1904 20.75 11.5V2.5C20.75 1.80964 20.1904 1.25 19.5 1.25ZM2.5 0C1.11929 0 0 1.11929 0 2.5V11.5C0 12.8807 1.11929 14 2.5 14H19.5C20.8807 14 22 12.8807 22 11.5V2.5C22 1.11929 20.8807 0 19.5 0H2.5ZM3 3.5H4H4.25H4.6899L4.98715 3.82428L7 6.02011L9.01285 3.82428L9.3101 3.5H9.75H10H11V4.5V10.5H9V6.79807L7.73715 8.17572L7 8.97989L6.26285 8.17572L5 6.79807V10.5H3V4.5V3.5ZM15 7V3.5H17V7H19.5L17 9.5L16 10.5L15 9.5L12.5 7H15Z"
                        fill="var(--ds-gray-700)"
                        fillRule="evenodd"
                      />
                    </svg>
                    supported.
                  </Text>
                </div>
                <div className={styles.actions}>
                  <span className={styles.emojisWrapper}>
                    {Array.from(EMOJIS.values()).map((emoji, index) => (
                      <button
                        aria-checked={selectedEmoji === emoji}
                        aria-label={`Select ${
                          EMOJI_DESCRIPTIONS[index] || ''
                        } emoji`}
                        className={styles.emoji}
                        key={emoji}
                        onClick={(): void => {
                          if (selectedEmoji === emoji) {
                            setSelectedEmoji(null);
                            return;
                          }

                          setSelectedEmoji(emoji);
                        }}
                        role="radio"
                        type="button"
                      >
                        {EMOJI_ICONS[index]}
                      </button>
                    ))}
                  </span>
                  <Button loading={loading} size="small" typeName="submit">
                    Send
                  </Button>
                </div>
              </form>
            </motion.div>
          ) : (
            <div className={styles.successWrapper} key="success">
              <IconCheckInCircle
                className="checkmark"
                color="var(--geist-success)"
                fill
                size="2rem"
              />
              <Text size={14}>Your feedback has been received!</Text>
              <Text size={14}>Thank you for your help.</Text>
            </div>
          )}
        </AnimatePresence>
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Root>
  );
}
